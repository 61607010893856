import axios from 'axios';
import { logCrumb } from 'utils/src/Logs';
import SentryCategories from 'utils/src/enums/SentryCategories';

const TOKEN_ENDPOINT = 'oauth2/token';
const REVOKE_ENDPOINT = 'oauth2/revoke';
const AUTHORIZATION_SERVER = 'https://cognito.benefitsapi.com/';

// TODO: add nonce checking to token receipt
const hash = async (s) => crypto.subtle.digest('sha-256', new TextEncoder().encode(s));
const hex = (c) =>
  Array.from(new Uint8Array(c))
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');

const nonce = (length) => {
  const r = new Uint8Array(length);
  return crypto.getRandomValues(r);
};

const base64UrlEncode = (s) => {
  return window
    .btoa(String.fromCharCode.apply(null, Array.from(s)))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
};

const base64UrlDecode = (s) => {
  return window.atob(s.replace(/_/g, '/').replace(/-/g, '+'));
};

const makePkceChallenge = async () => {
  const pkceVerifier = hex(nonce(63));
  const pkceChallenge = base64UrlEncode(new Uint8Array(await hash(pkceVerifier)));
  return { pkceChallenge, pkceVerifier };
};

const makePkceState = async ({ timestamp, ttlSeconds = 300, size = 128, signOnUrl, client, error, idp }) => {
  const expiry = timestamp + ttlSeconds;
  const state = base64UrlEncode(nonce(size));
  const { pkceChallenge, pkceVerifier } = await makePkceChallenge();
  const pkceState = {
    state,
    pkceChallenge,
    pkceVerifier,
    timestamp,
    expiry,
    authorizationServer: AUTHORIZATION_SERVER,
  };

  const mappedError = (() => {
    if (!error) return {};

    if (error === 'Request failed with status code 400') {
      return {
        error: "You've been auto-logged out for security. Please log in again.",
      };
    }

    return { error };
  })();

  const addIdp = idp ? { idp } : {};
  const query = new URLSearchParams({
    client,
    state,
    code_challenge: pkceChallenge,
    ...mappedError,
    ...addIdp,
  });

  const authUrl = `${signOnUrl}?${query.toString()}`;
  return { pkceState, authUrl };
};

const parseCredentials = (authentication_result) => {
  const { AccessToken, RefreshToken, IdToken, TokenType, ExpiresIn } = JSON.parse(authentication_result || '{}');
  return {
    access_token: AccessToken,
    refresh_token: RefreshToken,
    id_token: IdToken,
    expires_in: ExpiresIn,
    token_type: TokenType,
  };
};

const authorizationRequest = async (endpoint, body) => {
  const response = await axios.post(`${AUTHORIZATION_SERVER}${endpoint}`, new URLSearchParams(body), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  return response.data;
};

const parseJwtClaims = (token) => {
  const [, jwtClaimsPart] = token.split('.');
  if (!jwtClaimsPart) {
    return undefined;
  }
  return JSON.parse(base64UrlDecode(jwtClaimsPart));
};

const keyForState = (s) => `/auth/state/cognito/${s}`;

export const retrieveAndValidateState = (state) => {
  const key = keyForState(state);
  const session = JSON.parse(window.localStorage.getItem(key));
  window.localStorage.removeItem(key);

  if (!session) return logCrumb(SentryCategories.WARNING, 'No session!');

  const { expiry } = session;
  const currentTime = Math.floor(Date.now() / 1000);

  if (expiry < currentTime) {
    throw new Error('Session expired');
  }

  return session;
};

const completePkceAuth = async ({ code, client_id, session, redirect_uri }) => {
  const { pkceVerifier } = session;

  const response = await authorizationRequest(TOKEN_ENDPOINT, {
    grant_type: 'authorization_code',
    client_id,
    code,
    code_verifier: pkceVerifier,
    redirect_uri,
  });
  const token_expiry = parseJwtClaims(response.id_token)?.exp;
  return { expiry: token_expiry, ...response };
};

const refreshCredentials = async ({ refresh_token, client_id }) => {
  const response = await authorizationRequest(TOKEN_ENDPOINT, {
    grant_type: 'refresh_token',
    client_id,
    refresh_token,
  });

  const expiry = parseJwtClaims(response.id_token)?.exp;
  return { refresh_token, expiry, ...response };
};

const revokeToken = async ({ client_id, token }) => {
  return authorizationRequest(REVOKE_ENDPOINT, { client_id, token });
};

export {
  hash,
  hex,
  nonce,
  base64UrlDecode,
  base64UrlEncode,
  makePkceState,
  completePkceAuth,
  parseCredentials,
  parseJwtClaims,
  revokeToken,
  refreshCredentials,
  keyForState,
};
