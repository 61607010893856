import React, { useCallback } from 'react';
import { AuthenticationContext } from '@data/auth/transition';
import { NetworkErrorBoundary } from 'rest-hooks';
import { OverlayLoader, PageError, Message, SuspenseWrapper } from 'react-components';
import Button from 'react-components/src/components/button';
import current from '@config';
import Link from 'react-components/src/components/link';

import { openTicketLink } from 'utils/src/Support';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore -- auth is vanilla javascript for now
import { Status, useAuthCheck } from '@data/auth/Auth';
import NetworkFailureDash from './components/network-failure-dash';

type ErrorPageProps = React.PropsWithChildren<{
  logout?: () => unknown;
  user?: {
    email: string;
  };
}>;

const { MessageTypes } = Message;

const ErrorPage = ({ logout, children, user }: ErrorPageProps) => {
  const handleLogout = useCallback(() => {
    logout?.();
  }, [logout]);

  return (
    <PageError title="Invalid Email">
      {user?.email && (
        <p className="text-grayscale-100">
          Logged in as: <strong>{user.email}</strong>
        </p>
      )}
      <Message type={MessageTypes.ERROR}>
        That email isn’t registered with a Benepass account. Check for typos or please{' '}
        <Link size="md" className="underline" href={openTicketLink} shouldOpenNewTab>
          submit a ticket to our support
        </Link>
        .
      </Message>
      {children}
      <Button className="bg-red-100 justify-center" onClick={handleLogout}>
        Logout
      </Button>
    </PageError>
  );
};

type AuthCheckerProps = React.PropsWithChildren<{
  error?: string;
  logout?: () => unknown;
  user?: { email: string };
}>;

const AuthChecker = ({ logout, error, children, user }: AuthCheckerProps) => {
  const { status } = useAuthCheck();

  if (status === Status.loading) {
    return <OverlayLoader message="Fetching user data..." />;
  }
  if (status === Status.success) {
    return (
      <SuspenseWrapper loader={<OverlayLoader message="Loading..." />}>
        <NetworkErrorBoundary fallbackComponent={NetworkFailureDash}>{children}</NetworkErrorBoundary>
      </SuspenseWrapper>
    );
  }

  return (
    <ErrorPage logout={logout} user={user}>
      {error}
    </ErrorPage>
  );
};

type AuthenticatorProps = {
  loading?: boolean;
  children: JSX.Element;
};

const Authenticator = (props: AuthenticatorProps & AuthCheckerProps) => {
  const { children } = props;

  const removeKey = React.useCallback(() => window.localStorage.removeItem('access_token'), []);

  if (current.test) {
    return children;
  }

  return (
    <AuthenticationContext.Consumer>
      {({
        isLoading,
        isAuthenticated,
        logout,
        user,
      }: {
        isLoading: boolean;
        isAuthenticated: boolean;
        logout?: () => unknown;
        user?: { email: string };
      }) => {
        const loadingComponent = <OverlayLoader message="Authenticating user..." />;

        if (isLoading) {
          return loadingComponent;
        }

        if (!isAuthenticated) {
          removeKey();
          return loadingComponent;
        }
        // eslint-disable-next-line
        return <AuthChecker {...props} user={user} logout={logout} />;
      }}
    </AuthenticationContext.Consumer>
  );
};

export default Authenticator;
