import * as Icons from '@benepass/icons';
import React from 'react';

import { grayscale, indigo } from '@benepass/colors';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

import * as Tooltip from '../../../components/tooltip';
import customTwMerge from '../../../utils/twMerge';
import Text from '../../../components/text';
import * as Page from '../page';

type IconType = keyof typeof Icons;

type Props = Omit<React.ComponentProps<Link>, 'to'> & {
  icon?: IconType;
  active?: boolean;
  to?: string;
  theme?: 'dark' | 'light';
};

const SidebarButton = ({
  children,
  to = '',
  active = false,
  theme = 'dark',
  ...props
}: React.PropsWithChildren<Props>) => {
  const { isCollapsed } = Page.usePageContainer();

  const Icon = React.useMemo(() => {
    const dualtoneKey = `Dualtone${props.icon}Pink`;
    const key = active && Icons[dualtoneKey as IconType] ? dualtoneKey : props.icon;

    if (!props.icon || !Icons[key as IconType]) return React.Fragment;

    return Icons[key as IconType];
  }, [props.icon, active]);

  const color = React.useMemo(() => {
    if (theme === 'light') {
      return active ? grayscale['0'] : 'text-current';
    }

    return active ? indigo['100'] : 'text-current';
  }, [active, theme]);

  const className = React.useMemo(
    () =>
      customTwMerge(
        /**
         * @notes
         *  if active:
         *    a) light
         *      background: bg-grayscale-0
         *      focus: bg-indigo-90
         *    b) dark
         *      background: bg-indigo-100
         *      focus: bg-grayscale-2
         *  if inactive:
         *    a) light
         *      hover: bg-grayscale-8 text-grayscale-64
         *    b) dark
         *      hover:bg-indigo-90 text-indigo-20
         */
        active && theme === 'dark' ? 'bg-grayscale-0' : '',
        active && theme === 'light' ? 'bg-indigo-100' : '',
        !active && theme === 'dark'
          ? 'hover:bg-indigo-90 hover:text-indigo-20 focus:bg-indigo-90 focus:text-indigo-20'
          : '',
        !active && theme === 'light'
          ? 'hover:bg-grayscale-8 hover:text-grayscale-64 focus:bg-grayscale-8 focus:text-grayscale-64'
          : '',
        theme === 'dark' ? 'text-indigo-40' : 'text-grayscale-64',
        'active:scale-[0.985] transition-all focus:ring-1',
        'flex flex-row rounded items-center gap-3'
      ),
    [active, theme]
  );

  const Content = React.useMemo(() => {
    const paddingVertical = {
      paddingTop: isCollapsed ? 8 : 12,
      paddingBottom: isCollapsed ? 8 : 12,
    };

    const paddingHorizontal = {
      paddingRight: isCollapsed ? 8 : 16,
      paddingLeft: isCollapsed ? 8 : 16,
    };

    return (
      <motion.div
        className={className}
        initial={{
          paddingRight: 8,
          paddingLeft: 8,
          ...paddingVertical,
        }}
        animate={{
          ...paddingHorizontal,
          ...paddingVertical,
        }}
        transition={{ duration: 0.35, type: 'spring', bounce: 0 }}
      >
        {isCollapsed ? (
          <Tooltip.Root>
            <Tooltip.Trigger asChild>
              <motion.div>
                <div className="flex translate-x-[calc(50%-6px)]">
                  <Icon size="18px" color={color} />
                </div>
              </motion.div>
            </Tooltip.Trigger>

            <Tooltip.Content side="right" sideOffset={32} className="bg-grayscale-100 rounded-lg z-50 py-1 px-3">
              {typeof children === 'string' ? (
                <Text className="text-grayscale-0" size="sm">
                  {children}
                </Text>
              ) : (
                children
              )}
            </Tooltip.Content>
          </Tooltip.Root>
        ) : (
          <motion.div
            className="flex flex-row gap-3 items-center"
            initial={{ scale: 0.2 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0.8 }}
          >
            <motion.div>
              <Icon size="18px" color={color} />
            </motion.div>

            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ type: 'spring', duration: 0.3, bounce: 0 }}
            >
              {typeof children === 'string' ? (
                <Text
                  type="body"
                  size="lg"
                  className={customTwMerge(
                    (() => {
                      if (theme === 'dark') return active ? 'text-indigo-100' : 'text-current';
                      return active ? 'text-grayscale-0' : 'text-current';
                    })(),
                    active ? 'font-medium' : ''
                  )}
                >
                  {children}
                </Text>
              ) : (
                children
              )}
            </motion.div>
          </motion.div>
        )}
      </motion.div>
    );
  }, [children, isCollapsed, Icon, color, active, theme, className]);

  if (!to && props.href) {
    return <a {...props}>{Content}</a>;
  }

  return (
    <Link {...props} to={to || '#'} type="button" className="w-full">
      {Content}
    </Link>
  );
};

export default SidebarButton;
