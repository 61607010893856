import React from 'react';

import customTwMerge from '../../../utils/twMerge';
import useBreakpoint from 'utils/src/hooks/useBreakpoint';

import Text from '../../../components/text';

import { down } from 'styled-breakpoints';
import { findByType } from 'utils/src/react-utils';

export const Subtitle = ({ children, ...props }: React.ComponentProps<typeof Text>) => {
  const isMobile = useBreakpoint(down('sm'));

  return (
    <Text
      {...props}
      className={customTwMerge('text-grayscale-64', props.className)}
      type="body"
      size={isMobile ? 'lg' : 'xl'}
    >
      {children}
    </Text>
  );
};

export const Title = ({ children, ...props }: React.ComponentProps<typeof Text>) => {
  const isMobile = useBreakpoint(down('sm'));

  return (
    <Text
      {...props}
      className={customTwMerge('text-grayscale-100', props.className)}
      type={isMobile ? 'header-1' : 'display-2'}
    >
      {children}
    </Text>
  );
};

const Actions = ({ children, className, ...props }: React.HTMLProps<HTMLDivElement>) => {
  return (
    <div
      className={customTwMerge('flex flex-col md:flex-row flex-wrap gap-2 flex-grow justify-end', className)}
      {...props}
    >
      {children}
    </div>
  );
};

type Props = React.HTMLProps<HTMLDivElement>;

const Container = ({ ...props }: Props) => {
  const subtitle = findByType(props.children, Subtitle);
  const title = findByType(props.children, Title);
  const actions = findByType(props.children, Actions) || null;

  const remainingChildren = React.Children.toArray(props.children).filter(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (child) => !React.isValidElement(child) || ![Title, Subtitle, Actions].includes(child.type as any)
  );

  return (
    <div {...props} className={customTwMerge('flex flex-col', props.className)}>
      <div className="flex flex-row gap-2">
        <div className="flex flex-col">
          {title}
          {subtitle}
        </div>
        {actions}
      </div>
      {remainingChildren}
    </div>
  );
};

export default { Root: Container, Header: Title, Subtitle, Actions };
